import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(section, first, second, third, fourth, fifth, classPrizes ) {
  return { section, first, second, third, fourth, fifth, classPrizes };
}

const rows = [
  createData('Premier', '$1200', '$800', '$600', '$400', '$200', 'U2100 $250-150, Top Upset $200'),
  createData('U1900', '$800', '$600', '$400', '$200', '$100', 'U1700 $200-100'),
  createData('U1500', '$600', '$300', '$200', '$100'),
  createData('Top Female Player (any section)', '$100')
];

export default function BasicTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{fontFamily: 'Poppins'}}>Section</TableCell>
            <TableCell align="left" sx={{fontFamily: 'Poppins'}}>1st</TableCell>
            <TableCell align="left" sx={{fontFamily: 'Poppins'}}>2nd</TableCell>
            <TableCell align="left" sx={{fontFamily: 'Poppins'}}>3rd</TableCell>
            <TableCell align="left" sx={{fontFamily: 'Poppins'}}>4th</TableCell>
            <TableCell align="left" sx={{fontFamily: 'Poppins'}}>5th</TableCell>
            <TableCell align="left" sx={{fontFamily: 'Poppins'}}>Class Prizes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{fontFamily: 'Poppins'}} >
                {row.section}
              </TableCell>
              <TableCell align="left" sx={{fontFamily: 'Poppins'}}>{row.first}</TableCell>
              <TableCell align="left" sx={{fontFamily: 'Poppins'}}>{row.second}</TableCell>
              <TableCell align="left" sx={{fontFamily: 'Poppins'}}>{row.third}</TableCell>
              <TableCell align="left" sx={{fontFamily: 'Poppins'}}>{row.fourth}</TableCell>
              <TableCell align="left" sx={{fontFamily: 'Poppins'}}>{row.fifth}</TableCell>
              <TableCell align="left" sx={{fontFamily: 'Poppins'}}>{row.classPrizes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}