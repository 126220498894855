import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import HomeImg from "../assets/conner-baker-ebL5L8M1tqI-unsplash.jpg"
import Footer from "../components/Footer";
import { Typography } from "@mui/material";
import PrizeGrid from "../components/PrizeGridPresidents"
import "../styles.css"

function PresidentsRoute() {
    return (
        <>
        <Navbar></Navbar>
        <Hero
            className="hero-mid"
            heroImg={HomeImg}
            title="2nd Annual Presidents' Day Open"
            btnClass="show"
            url="https://caissachess.net/online-registration/index/5472"
            buttonText="REGISTER NOW"
            adv_entries_url="https://caissachess.net/online-registration/preregistration-list/5472"
        ></Hero>
        <br></br>
        <div className="details">
            <Typography color="black" variant="h4" sx={{fontFamily: 'Poppins', fontWeight:'fontWeightBold'}}>
                * GM and IM Norms Possible in Premier Section *
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Washington Dulles Airport Marriott
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                45020 Aviation Dr, Dulles, VA 20166
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                February 13 - 17, 2025
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                $10,500 in cash prizes based on 120 paid entries
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Sections: Premier (9 Round Swiss), U2300 and U1900 (7 Round Swiss), U1500 (5 Round Swiss)
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Up to two irrevocable half-point byes allowed. Byes must be committed 1 hr before Round 2.
            </Typography>
            <br></br><br></br>
            <Typography color="black" variant="h5" sx={{fontFamily: 'Poppins'}}>
            Schedule:
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Premier: 2/13 at 7 PM, 2/14 at 10 AM and 4 PM, 2/15 at 10 AM and 4 PM, 2/16 at at 10 AM and 4 PM, 2/17 at 9 AM and 3 PM
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                The Premier section only has a 5 Day schedule.
            </Typography>
            
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                U2300/U1900 4 Day: 2/14 at 7 PM, 2/15 at 10 AM and 4 PM, 2/16 at 10 AM and 4 PM, 2/17 at 9 AM and 3 PM
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                U2300/U1900 3 Day: 2/15 at 10 AM and 1 PM, schedules merge starting round 3.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                U1500: 2/15 at 10 AM and 1 PM and 4 PM,  2/16 at 9 AM and 1 PM
            </Typography>
            <br></br><br></br>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Hotel Reservations: Chess Rate of $128/night + taxes/fees. Last day to get the chess rate is 01/23/25.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Must mention 'Atlantic Chess Association' over the phone at 1-800-228-9290 or 703-471-9500.
            </Typography>
            
            <br></br><br></br>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Conditions: Must commit by 12/31/24 and play all nine rounds to qualify for conditions.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Reach out to organizers at director@atlanticchessassociation.com for conditions information.
            </Typography>
            
            <div className="prizeGrid">
                <Typography color="black" variant="h4" sx={{fontFamily: 'Poppins'}}>
                    Prize Structure
                </Typography>
                <PrizeGrid></PrizeGrid>
            </div>
            
        </div>
        <Footer></Footer>
        </>
    )
}

export default PresidentsRoute;