import Navbar from "../components/Navbar";
import Hero2 from "../components/Hero2";
import HomeImg from "../assets/blitz-board.jpg"
import Footer from "../components/Footer";
// import { Typography } from "@mui/material";
// import PrizeGrid from "../components/PrizeGrid"
import "../styles.css"

function HomeRoute() {
    return (
        <>
        <Navbar></Navbar>
        <Hero2
            className="hero"
            heroImg={HomeImg}
            tournament1_title="MLK Day Open"
            tournament1_prizeFund="$7500 Total Prize Fund"
            tournament1_text="January 17 - 19, 2025"
            tournament1_text2="Crowne Plaza Houston Galleria"
            tournament1_text3="7611 Katy Freeway, Houston, Texas 77024"
            tournament1_url="/mlk"
            tournament1_adv_entries="https://caissachess.net/online-registration/preregistration-list/5458"
            btnClass="show"
            buttonText="DETAILS"
            buttonAdvEntries="ADV ENTRIES"
            tournament2_title="Presidents' Day Open"
            tournament2_prizeFund="$10500 Total Prize Fund"
            tournament2_text="February 13 - 17, 2025"
            tournament2_text2="Washington Dulles Airport Marriott"
            tournament2_text3="45020 Aviation Dr, Dulles, VA 20166"
            tournament2_url="/presidents"
            tournament2_adv_entries="https://caissachess.net/online-registration/preregistration-list/5472"
        ></Hero2>
        {/* <Hero
            className="hero"
            heroImg={HomeImg}
            title="Presidents' Day Open"
            prizeFund="$5650 Total Prize Fund   "
            text="November 29 - December 1, 2024"
            text2="Washington Dulles Airport Marriott"
            text3="45020 Aviation Dr, Dulles, VA 20166"
            url="/presidents"
            btnClass="show"
            buttonText="REGISTER NOW"
        ></Hero> */}
        {/* <div className="details">
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                * given 80 paid entries
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Sections: Premier (FIDE-Rated - min rating 1800 USCF/FIDE), U1900, U1600, U1300
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                5 Round Swiss; Up to two irrevocable half-point byes allowed. Byes must be committed 1 hr before Round 2.
            </Typography>
            <Typography color="black" variant="h5" sx={{fontFamily: 'Poppins'}}>
            Schedule:
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
            3 Day: 11/29 at 7 PM, 11/30 at 10 AM and 4 PM, 12/01 at 9 AM and 3 PM (G/90+30)
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
            2 Day: 11/30 at 10 AM and 1 PM (G/60;d5), schedules merge starting round 3
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
            Premier section has only a 3 Day schedule.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Default Forfeit: 1 hour or full time control for rounds less than 60 mins. 
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Hotel Reservations: Chess Rate of $109/night + taxes/fees. Last day to get the chess rate is 11/08/2024.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Must mention 'Atlantic Chess Association' over the phone at 1-800-228-9290 or 703-471-9500.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Bughouse: Friday November 29, first round starts 1:00PM. 4 round double Swiss, G/5+0.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Blitz: Saturday November 30, approximately 8:30PM. Prizes: $125/$75. 4 round double Swiss, G/5+0.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Scholastic: Saturday November 30, first round starts 9:00AM. 4 round Swiss, G/30;d5.
            </Typography>
            
            <div className="prizeGrid">
                <Typography color="black" variant="h4" sx={{fontFamily: 'Poppins'}}>
                    Prize Structure
                </Typography>
                <PrizeGrid></PrizeGrid>
            </div>
             */}
        {/* </div> */}
        <Footer></Footer>
        </>
    )
}

export default HomeRoute;