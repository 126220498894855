import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import HomeImg from "../assets/elizabeth-villalta-acyfdWkMUSc-unsplash.jpg"
import Footer from "../components/Footer";
import { Typography } from "@mui/material";
import PrizeGrid from "../components/PrizeGridMLK"
import "../styles.css"

function MLKRoute() {
    return (
        <>
        <Navbar></Navbar>
        <Hero
            className="hero-mid"
            heroImg={HomeImg}
            title="MLK Day Open"
            btnClass="show"
            buttonText="REGISTER NOW"
            url="https://caissachess.net/online-registration/index/5458"
            adv_entries_url="https://caissachess.net/online-registration/preregistration-list/5458"
        ></Hero>
        <br></br>
        <div className="details">
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Crowne Plaza Houston Galleria
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                7611 Katy Freeway, Houston, Texas 77024
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                January 17 - 19, 2025
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                $7500 in cash prizes based on 150 paid entries
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Sections: Premier (FIDE-Rated - min rating 1800 USCF/FIDE), U1900, U1500
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                5 Round Swiss; Up to two irrevocable half-point byes allowed. Byes must be committed 1 hr before Round 2.
            </Typography>
            <br></br><br></br>
            <Typography color="black" variant="h5" sx={{fontFamily: 'Poppins'}}>
                Schedule:
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                3 Day: 01/17 at 7 PM, 01/18 at 10 AM and 4 PM, 01/19 at 9 AM and 3 PM
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                2 Day: 01/18 at 10 AM and 1 PM, schedules merge starting round 3
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
            Premier section has only a 3 Day schedule.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Default Forfeit: 1 hour or full time control for rounds less than 60 mins. 
            </Typography>
            <br></br><br></br>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Hotel Reservations: $89/night + taxes/fees. Last day to get the chess rate is 01/03/2025.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Must mention 'Atlantic Chess Association' over the phone at (713) 680-2222 or use <a target='_blank' rel="noreferrer" href="https://www.ihg.com/crowneplaza/hotels/us/en/find-hotels/select-roomrate?fromRedirect=true&qSrt=sBR&qIta=99801505&icdv=99801505&qDest=Crowne%20Plaza%20Houston%20Galleria%20Area&qErm=false&qSlH=houtg&qRms=1&qAdlt=1&qChld=0&qCiD=17&qCiMy=002025&qCoD=19&qCoMy=002025&qGrpCd=ACA&qAAR=6CBARC&qRtP=6CBARC&setPMCookies=true&qSHBrC=CP&qpMbw=0&qpMn=0&srb_u=1&qChAge=&qRmFltr=">link</a>.
            </Typography>
            <br></br><br></br>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Blitz: Saturday, January 18, approximately 9:00 PM. 4 round double Swiss, G/5+0. <a target='_blank' rel="noreferrer" href="https://caissachess.net/online-registration/index/5459">More details</a>.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Scholastic: Monday, January 20, first round starts 9:00AM. 4 round Swiss, G/30;d5. <a target='_blank' rel="noreferrer" href="https://caissachess.net/online-registration/index/5506">More details</a>.
            </Typography>
            
            <div className="prizeGrid">
                <Typography color="black" variant="h4" sx={{fontFamily: 'Poppins'}}>
                    Prize Structure
                </Typography>
                <PrizeGrid></PrizeGrid>
            </div>
            
        </div>
        <Footer></Footer>
        </>
    )
}

export default MLKRoute;