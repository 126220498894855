import "./OtherHero.css";

function Hero2(props) {
    return (
        <>
        <div className={props.className}>
            <img alt="heroImg" src={props.heroImg}>
            </img>
            <div className="hero2-text">
                <h1>{props.tournament1_title}</h1>
                <h1>{props.tournament1_title2}</h1>
                <h2>{props.tournament1_prizeFund}</h2>
                <div className="textInfo">
                    <h3>{props.tournament1_text}</h3>
                    <h3>{props.tournament1_text2}</h3>
                    <h3>{props.tournament1_text3}</h3>
                </div>
                <a href={props.tournament1_url} className={props.btnClass} style={{margin: 5}}>{props.buttonText}</a>
                <a href={props.tournament1_adv_entries} className={props.btnClass}>{props.buttonAdvEntries}</a>
                <br></br>
                <br></br>
                <br></br>
                <h1>{props.tournament2_title}</h1>
                <h1>{props.tournament2_title2}</h1>
                <h2>{props.tournament2_prizeFund}</h2>
                <div className="textInfo">
                    <h3>{props.tournament2_text}</h3>
                    <h3>{props.tournament2_text2}</h3>
                    <h3>{props.tournament2_text3}</h3>
                </div>
                <a href={props.tournament2_url} className={props.btnClass} style={{margin: 5}}>{props.buttonText}</a>
                <a href={props.tournament2_adv_entries} className={props.btnClass} style={{margin: 5}}>{props.buttonAdvEntries}</a>
            </div>
        </div>
        </>
    )
}

export default Hero2;